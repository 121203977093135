import React, {useEffect, useState} from 'react';

const useImagePreloader = (p_pageImages) => {

    const [imageIsReady, setImageIsReady] = useState(false);
    const [pageImages, setPageImages] = useState(p_pageImages? [...p_pageImages] : null);    
    const [imagesReadyCnt, setImagesReadyCnt] = useState(0);
    
    useEffect(()=>{
        let i = 0;

        if(pageImages)
        {
            pageImages.map( pgi => {
                const img = new Image();
                img.onload = () => {
                    // when it finishes loading, update the component state
                    i++;

                    setImagesReadyCnt(i)
                    
                    if(i === pageImages.length){
                        setImageIsReady(true);
                    } 



                }
                img.src = pgi; 
            }) 
        }     
        else
        {
            setImageIsReady(true);
        }  

    },[])

    return [imageIsReady,imagesReadyCnt]
}

export default useImagePreloader;