import React, {useEffect, useState} from 'react'
import './App.css';
import Rosary from './components/Rosary';

import playIcon from './assets/images/play.svg';
import stop from './assets/images/stop.svg';
import pauseIcon from './assets/images/pause.svg';
import next from './assets/images/next.svg';
import previous from './assets/images/previous.svg';
import background01 from './assets/images/background_01.jpg';

//import Loader from 'react-loader-spinner'; 
import { Howl } from "howler"; 
import PreloaderView from './components/PreloaderView';
import PullRelease from './components/PullRelease/PullRelease';

import {Audio as SoundLoader} from 'react-loader-spinner'; 

import ReactGA from 'react-ga';

// Loader
/* const CustomLoader = () => {
    return (<div style={{height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
         
    <div>
    <div style={{justifyContent:'center', display:'flex', flexDirection:'column'}}><Loader type="ThreeDots" color="#0099ff" height="50" width="100" /></div>

       <span style={{fontSize:20, color:'#777777'}}>Loading...</span>
    </div>
    
</div>)
} */


// Splash
const Splash = () =>
{
  return(
    <div>
      Holy Rosay

      Let's pray.

      Loading...
    </div>
  )
}

// Utility functions
const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const daysOfTheWeekLabels = {
  "SUN":{
    label:"Sunday"
  }, 
  "MON":{
    label:"Monday"
  }, 
  "TUE":{
    label:"Tuesday"
  }, 
  "WED":{
    label:"Wednesday"
  }, 
  "THU":{
    label:"Thursday"
  }, 
  "FRI":{
    label:"Friday"
  }, 
  "SAT":{
    label:"Saturday"
  }
}

const getDayOfWeek = () => {
  let d = new Date();
  let day = d.getDay();
  let dayOfWeek = daysOfTheWeek[day];
  let dayOfWeekLabel = daysOfTheWeekLabels[dayOfWeek].label

  return {day:dayOfWeek, label:dayOfWeekLabel}
}





const TRACKING_ID = "UA-104993010-3";//"G-V1EQ4ZSE1K";

ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const [isAudioPlaying, setIsAudioPlaying] = React.useState(false);
  const [isAudioPaused, setIsAudioPaused] = React.useState(false);
  const [audios, setAudios] = useState({});

  

  const [preloadedResources, setPreloadedResources] = useState({
    "audios":[
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Apostles+Creed/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Apostles+Creed/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/03_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/04_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/05_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/06_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Eternal+Rest/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Eternal+Rest/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Fatima+Prayer/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Fatima+Prayer/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Glory+Be/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Glory+Be/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/03_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/04_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/05_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/06_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Mary/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Mary/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Our+Father/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Our+Father/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sign+of+the+Cross/Lead_01.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/03_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/04_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/01_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/02_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/03_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/04_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/05_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/06_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/07_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/08_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/09_Lead.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/10_Response.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/0.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/1.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/2.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/3.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/4.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/5.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/0.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/1.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/2.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/3.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/4.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/5.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/0.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/1.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/2.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/3.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/4.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/5.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/0.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/1.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/2.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/3.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/4.mp3",
      "https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/5.mp3",
    ],
    "images":[]
  });

  const playAudio = () =>
  {
    play()
  }

  const prayerSectionCounter = React.useRef(0)
  const currentRosaryPositionRef = React.useRef(0)
  const currentRosaryPrayerPostionRef = React.useRef(0)
  const currentlyPlayingStep = React.useRef(null)
 // const prayerSectionCounter = React.useRef(0)

  const pause = () => {
    if(currentlyPlayingStep.current)
    {
      currentlyPlayingStep.current.pause();

      setIsAudioPlaying(false);
      setIsAudioPaused(true);
    }
  }

  const continueAudio = () => {
    if(currentlyPlayingStep.current)
    {
      currentlyPlayingStep.current.play();

      setIsAudioPlaying(true);
      setIsAudioPaused(false);
    }
  }

  const play = () =>
  {
    
    setIsAudioPlaying(true);

    let rosaryPositionStep = rosaryPositions[currentRosaryPositionRef.current];

    

    let prayerKey = rosaryState[rosaryPositionStep][currentRosaryPrayerPostionRef.current];

    //console.log({rosaryPositionStep, prayerKey});

    let prayer = getPrayer(prayerKey);

    

    let prayerSections = prayer.prayerSections;

    console.log({cc:prayerSectionCounter.current ,ll:prayerSections.length})

    if(prayerSectionCounter.current < prayerSections.length)
    {

      prayerSectionCounter.current = prayerSectionCounter.current +1;

     /*  audios[prayerSections[prayerSectionCounter.current-1].audio].once('end', (function(){                               
        //setIsInstructorTalking(false);
        //onEndAction && onEndAction(); 
        play();   
                
      })()) */

      currentlyPlayingStep.current = audios[prayerSections[prayerSectionCounter.current-1].audio];
      setSounds(currentlyPlayingStep.current)
      currentlyPlayingStep.current.once('end',   ()=>                             
        //setIsInstructorTalking(false);
        //onEndAction && onEndAction(); 
        playAudio()
                
      )

      currentlyPlayingStep.current.play();
    }
    else if(currentRosaryPosition < rosaryPositions.length-1)
    {
      prayerSectionCounter.current = 0;

      navigateToNext();
      
      play();
    }

    console.log({c : prayerSectionCounter.current, l:rosaryPositions.length, currentRosaryPosition, rosaryPositionStep, prayerKey, prayer});

    // Get Step Prayers
  }

  const speak = (audio, onEndAction) => {

    if(audio === null || audio === undefined) return;

    /*// Stop previous audio
    if(activeTeacherAudio)
    {
       // console.log(activeTeacherAudio)
        activeTeacherAudio.stop();
    }

    // set the current audio as the active teacher/speaker audio
    setActiveTeacherAudio(audio);
    //console.log("NN",audio)*/

    audio.once('end', function(){                               
      setIsAudioPlaying(false);
        onEndAction && onEndAction();            
    })

    setIsAudioPlaying(true);
    audio.play();

}


  const [mystries, setMysteries] = React.useState({
    "joyful_mysteries":{
      title:"The Joyful Mysteries",
      audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/0.mp3",
      days:["MON","SAT"],
      mystries:[
        {
          title:"The Annunciation", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/1.mp3",
        },
        {
          title:"The Visitation", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/2.mp3",
        },
        {
          title:"The Birth of our Lord Jesus", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/3.mp3",
        },
        {
          title:"The Presentation", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/4.mp3",
        },
        {
          title:"The Finding in the Temple", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Joyful+Mysteries/5.mp3",
        }
      ]
    },
    "sorrowful_mysteries":{
      title:"The Sorrowful Mysteries",
      audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/0.mp3",
      days:["TUE","FRI"],
      mystries:[
        {
          title:"The Agony in the Garden", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/1.mp3",
        },
        {
          title:"The Scourging at the Pillar", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/2.mp3",
        },
        {
          title:"The Crowning with Thorns", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/3.mp3",
        },
        {
          title:"The Carrying of the Cross", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/4.mp3",
        },
        {
          title:"The Crucifixion and Death", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Sorrowful+Mysteries/5.mp3",
        }
      ]
    },
    "glorious_mysteries":{
      title:"The Glorious Mysteries",
      audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/0.mp3",
      days:["WED","SUN"],
      mystries:[
        {
          title:"The Resurrection", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/1.mp3",
        },
        {
          title:"The Ascension", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/2.mp3",
        },
        {
          title:"The Descent of the Holy Spirit", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/3.mp3",
        },
        {
          title:"The Assumption", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/4.mp3",
        },
        {
          title:"The Coronation", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Glorious+Mysteries/5.mp3",
        }
      ]
    },
    "luminous_mysteries":{
      title:"The Luminous Mysteries",
      audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/0.mp3",
      days:["THU"],
      mystries:[
        {
          title:"The Baptism", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/1.mp3",
        },
        {
          title:"The Self-Revelation", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/2.mp3",
        },
        {
          title:"The Proclamation", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/3.mp3",
        },
        {
          title:"The Transfiguration", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/4.mp3",
        },
        {
          title:"The Institution of the Eucharist", 
          audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/The+Mysteries/Luminous+Mysteries/5.mp3",
        }
      ]
    }
  });
  /*  const [rosaryState, setRosaryState] = React.useState({
    "cross":["sign_of_the_cross", "come_holy_spirit"],
    "0-0":["our_father"],
    "0-1:0-3":["hail_mary"]
  }); */

  const [mysteryPositions, setMysteryPositions] = React.useState({
    1:[
      "1-P",
      "1-0",
      "1-1",
      "1-2",
      "1-3",
      "1-4",
      "1-5",
      "1-6",
      "1-7",
      "1-8",
      "1-9",
      "1-10",
    ],
    2:[
      "2-P",
      "2-0",
      "2-1",
      "2-2",
      "2-3",
      "2-4",
      "2-5",
      "2-6",
      "2-7",
      "2-8",
      "2-9",
      "2-10",
    ],
    3:[
      "3-P",
      "3-0",
      "3-1",
      "3-2",
      "3-3",
      "3-4",
      "3-5",
      "3-6",
      "3-7",
      "3-8",
      "3-9",
      "3-10",
    ],
    4:[
      "4-P",
      "4-0",
      "4-1",
      "4-2",
      "4-3",
      "4-4",
      "4-5",
      "4-6",
      "4-7",
      "4-8",
      "4-9",
      "4-10"
    ],
    5:[
      "5-P",
      "5-0",
      "5-1",
      "5-2",
      "5-3",
      "5-4",
      "5-5",
      "5-6",
      "5-7",
      "5-8",
      "5-9",
      "5-10",
    ]
  })

  const [rosaryPositions, setRosaryPositions] = React.useState([
    "cross",
    "0-0",
    "0-1",
    "0-2",
    "0-3",

    "1-P",
    "1-0",
    "1-1",
    "1-2",
    "1-3",
    "1-4",
    "1-5",
    "1-6",
    "1-7",
    "1-8",
    "1-9",
    "1-10",

    "2-P",
    "2-0",
    "2-1",
    "2-2",
    "2-3",
    "2-4",
    "2-5",
    "2-6",
    "2-7",
    "2-8",
    "2-9",
    "2-10",

    "3-P",
    "3-0",
    "3-1",
    "3-2",
    "3-3",
    "3-4",
    "3-5",
    "3-6",
    "3-7",
    "3-8",
    "3-9",
    "3-10",

    "4-P",
    "4-0",
    "4-1",
    "4-2",
    "4-3",
    "4-4",
    "4-5",
    "4-6",
    "4-7",
    "4-8",
    "4-9",
    "4-10",

    "5-P",
    "5-0",
    "5-1",
    "5-2",
    "5-3",
    "5-4",
    "5-5",
    "5-6",
    "5-7",
    "5-8",
    "5-9",
    "5-10",

    "end"
  ])
  const [rosaryState, setRosaryState] = React.useState({
    "cross":["sign_of_the_cross", "come_holy_spirit", "thou_o_lord", "apostles_creed"],
    "0-0":["our_father"],
    "0-1":["hail_mary"],
    "0-2":["hail_mary"],
    "0-3":["hail_mary"],
    
    "1-P":["glory_be", "fatima_prayer", "eternal_rest_prayer", "first_mystery"],
    "1-0":["our_father"],
    "1-1":["hail_mary"],
    "1-2":["hail_mary"],
    "1-3":["hail_mary"],
    "1-4":["hail_mary"],
    "1-5":["hail_mary"],
    "1-6":["hail_mary"],
    "1-7":["hail_mary"],
    "1-8":["hail_mary"],
    "1-9":["hail_mary"],
    "1-10":["hail_mary"],

    "2-P":["glory_be", "fatima_prayer", "eternal_rest_prayer", "second_mystery"],
    "2-0":["our_father"],
    "2-1":["hail_mary"],
    "2-2":["hail_mary"],
    "2-3":["hail_mary"],
    "2-4":["hail_mary"],
    "2-5":["hail_mary"],
    "2-6":["hail_mary"],
    "2-7":["hail_mary"],
    "2-8":["hail_mary"],
    "2-9":["hail_mary"],
    "2-10":["hail_mary"],

    "3-P":["glory_be", "fatima_prayer", "eternal_rest_prayer", "third_mystery"],
    "3-0":["our_father"],
    "3-1":["hail_mary"],
    "3-2":["hail_mary"],
    "3-3":["hail_mary"],
    "3-4":["hail_mary"],
    "3-5":["hail_mary"],
    "3-6":["hail_mary"],
    "3-7":["hail_mary"],
    "3-8":["hail_mary"],
    "3-9":["hail_mary"],
    "3-10":["hail_mary"],

    "4-P":["glory_be", "fatima_prayer", "eternal_rest_prayer", "fourth_mystery"],
    "4-0":["our_father"],
    "4-1":["hail_mary"],
    "4-2":["hail_mary"],
    "4-3":["hail_mary"],
    "4-4":["hail_mary"],
    "4-5":["hail_mary"],
    "4-6":["hail_mary"],
    "4-7":["hail_mary"],
    "4-8":["hail_mary"],
    "4-9":["hail_mary"],
    "4-10":["hail_mary"],

    "5-P":["glory_be", "fatima_prayer", "eternal_rest_prayer", "fifth_mystery"],
    "5-0":["our_father"],
    "5-1":["hail_mary"],
    "5-2":["hail_mary"],
    "5-3":["hail_mary"],
    "5-4":["hail_mary"],
    "5-5":["hail_mary"],
    "5-6":["hail_mary"],
    "5-7":["hail_mary"],
    "5-8":["hail_mary"],
    "5-9":["hail_mary"],
    "5-10":["hail_mary"],

    "end":["glory_be", "fatima_prayer", "eternal_rest_prayer", "hail_holy_queen", "sacred_heart_of_jesus"]
  });

  // Navigation.
  const [mysteryPosition, setMysteryPosition] = React.useState(0);
  const [mysteryPrayerPosition, setMysteryPrayerPosition] = React.useState(0);
  const [currentMystery, setCurrentMystery] = React.useState(null);
  const [currentRosaryPosition, setCurrentRosaryPosition] = React.useState(0);
  const [currentRosaryPrayerPostion, setCurrentRosaryPrayerPostion] = React.useState(0);
  const [audioRate, setAudioRate] = React.useState(1);
  const [Sounds, setSounds] = useState(null)
  const [pos, setPos] =  useState(0);

  function SoundPlay() {
    //Sounds.stop(); 
    Sounds.play();
    console.log("played");
  }

  function SoundPause() {
    Sounds.pause();
    console.log("paused");
  }

  function LogPos() {  
    console.log("sound pos", Sounds.seek(2));
  }

  function setSeek(e)
  {
    const {val} = e.target
    Sounds?.seek(val);
    setPos(val);
  }

  function updateAudioRate(rate){
    //Sounds?.rate(rate);
    setAudioRate(rate);
  }

 /*  React.useEffect(()=>{

    setSounds(new Howl({

      src: ["/ITLT_WELCOME_HSSE1.mp3"],

      onseek:()=>console.log("seeking")

    }))   

  },[]) */

  React.useEffect(()=>{
    Sounds?.rate(audioRate);
  }, [audioRate])

 

  React.useEffect(()=>{ 

    if(Sounds)
    {

      Sounds?.rate(audioRate);

      let iseek = setInterval(()=>{
        setPos(Sounds.seek());
        //console.log("sound pos", Sounds.seek());
      }, 50); 

      return ()=>clearInterval(iseek)

    }

  },[Sounds])

 

  const reset = () => {
    setMysteryPosition(0);
    setMysteryPrayerPosition(0);
  }  

  useEffect(()=>{

    //currentRosaryPositionRef.current = currentRosaryPosition;
    currentRosaryPrayerPostionRef.current = currentRosaryPrayerPostion;
  
   },[currentRosaryPrayerPostion])

   useEffect(()=>{

    currentRosaryPositionRef.current = currentRosaryPosition;
    //currentRosaryPrayerPostionRef.current = currentRosaryPrayerPostion;
  
   },[currentRosaryPosition])

  const navigateToNext = () =>{

    /*let dsd = Object.keys(rosaryPositions)
      let dd = Object.keys(rosaryState)*/

      let currentPosition = currentRosaryPositionRef.current;

      if(currentRosaryPrayerPostionRef.current < rosaryState[rosaryPositions[currentPosition]].length -1)
      {
        currentRosaryPrayerPostionRef.current =  currentRosaryPrayerPostionRef.current +1
 
        setCurrentRosaryPrayerPostion(c => c + 1)
      }

      else if(currentRosaryPosition < rosaryPositions.length -1)
      {

        currentRosaryPrayerPostionRef.current =  0
        currentRosaryPositionRef.current = currentRosaryPositionRef.current +1
 
        setCurrentRosaryPosition(p => p + 1)
        setCurrentRosaryPrayerPostion(0)
        setMysteryPosition(getMysteryPositionIndex(rosaryPositions[currentPosition]))
      }
  }

  const navigateToPrevious = () =>{

    let currentPosition = currentRosaryPosition;

    if(currentRosaryPosition > 0)
    {


      setCurrentRosaryPosition(p => p - 1)
      setCurrentRosaryPrayerPostion(0)
      setMysteryPosition(getMysteryPositionIndex(rosaryPositions[currentPosition]))
    }
    else if(currentRosaryPrayerPostion > 0)
    {
      setCurrentRosaryPrayerPostion(c => c - 1)
    }    
  }

  const [prayers, setPrayers] = React.useState({
    
        'sign_of_the_cross':{
          title:"Sign of the Cross",
          prayerSections:[
            {
              type:"lead",
              text:"In the name of the Father, and of the Son, and of the Holy Spirit. Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sign+of+the+Cross/Lead_01.mp3",
            }            
          ]
        },
        'eternal_rest_prayer':{
          title:"Eternal Rest",
          prayerSections:[
            {
              type:"lead",
              text:"May the souls of all the faithful departed.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Eternal+Rest/01_Lead.mp3",
            },
            {
              type:"response",
              text:"Through the mercy of God rest in peace, Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Eternal+Rest/02_Response.mp3",
            }
          ],
        },
        'come_holy_spirit':{
          title:"Come, Holy Spirit",
          prayerSections:[
            {
              type:"lead",
              text:"Come Holy Spirit and fill the hearts of the faithful.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/01_Lead.mp3",
            },
            {
              type:"response",
              text:"And enkindle in them the fire of your love.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/02_Response.mp3",
            },
            {
              type:"lead",
              text:"Send forth thy Spirit and they shall be recreated.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/03_Lead.mp3",
            },
            {
              type:"response",
              text:"And thou shalt renew the face of the earth.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/04_Response.mp3",
            },
            {
              type:"lead",
              text:"Let us pray.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/05_Lead.mp3",
            },
            {
              type:"response",
              text:"O God, who by the light of the Holy Spirit, did instruct the hearts of the " +
              "faithful, grant that in the same spirit we may be truly wise and ever " +
              "rejoice in his consolation through Christ our Lord, Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Come+Holy+Spirit/06_Response.mp3",
            }
          ]
        },
        'thou_o_lord':{
          title:"Thou O Lord",
          prayerSections:[
            {
              type:"lead",
              text:"Thou O Lord shall open my lips.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/01_Lead.mp3",
            },
            {
              type:"response",
              text:"And my tongue shall proclaim thy praise.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/02_Response.mp3",
            },
            {
              type:"lead",
              text:"Incline unto my aid O God.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/03_Lead.mp3",
            },
            {
              type:"response",
              text:"O Lord make haste to help me.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Thou+O+Lord/04_Response.mp3",
            }
          ],
        },
        'glory_be':{
          title:"Glory Be",
          prayerSections:[
            {
              type:"lead",
              text:"Glory be to the Father, and to the Son and to the Holy Spirit.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Glory+Be/01_Lead.mp3",
            },
            {
              type:"response",
              text:"As it was in the beginning is now and ever shall be, world " +
              "without end. Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Glory+Be/02_Response.mp3",
            }
          ],
        },
        'apostles_creed':{
          title:"Apostles' Creed",
          prayerSections:[
            {
              type:"lead",
              text:"I believe in God the Father Almighty, Maker of heaven and earth. " +
              "And in Jesus Christ, His only Son, our Lord; Who was conceived by " +
              "the Holy Spirit; Born of the Virgin Mary; Suffered under Pontius " +
              "Pilate; Was crucified, dead and buried; He descended into Hell; " +
              "The third day He rose again from the dead; He ascended into " +
              "heaven; And sitteth on the right hand of God the Father Almighty; " +
              "From thence He shall come to judge the living and the dead.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Apostles+Creed/01_Lead.mp3",
            },
            {
              type:"response",
              text:"I believe in the Holy Spirit; The Holy Catholic Church, the " +
              "Communion of Saints; The Forgiveness of sins; The Resurrection of " +
              "the body; And the life everlasting. Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Apostles+Creed/02_Response.mp3",
            }
          ],
        },
        'our_father':{
          title:"Our Father",
          prayerSections:[
            {
              type:"lead",
              text:"Our Father, who art in heaven, hallowed be thy name; thy " +
              "kingdom come; thy will be done on earth as it is in heaven.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Our+Father/01_Lead.mp3",
            },
            {
              type:"response",
              text:"Give us this day our daily bread; and forgive us our trespasses as we " +
              "forgive those who trespass against us; and lead us not into " +
              "temptation, but deliver us from evil. Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Our+Father/02_Response.mp3",
            }
          ],
        },
        'hail_mary':{
          title:"Hail Mary",
          prayerSections:[
            {
              type:"lead",
              text:"Hail Mary, full of grace, the Lord is with thee, blessed art thou " +
              "among women and blessed is the fruit of thy womb, Jesus.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Mary/01_Lead.mp3",
            },
            {
              type:"response",
              text:"Holy Mary, Mother of God, Pray for us sinners now and at " +
              "the hour of our death, Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Mary/02_Response.mp3",
            }
          ],
        },
        'fatima_prayer':{
          title:"Fatima Prayer",
          prayerSections:[
            {
              type:"lead",
              text:"Oh my Jesus.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Fatima+Prayer/01_Lead.mp3",
            },
            {
              type:"response",
              text:"Forgive us our sins, save us from the fires of hell, lead all " +
              "souls to heaven especially those who have most need of thy mercy. Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Fatima+Prayer/02_Response.mp3",
            }
          ],
        },
        'jesus_have_mercy':{
          title:"Jesus Have Mercy",
          prayerSections:[
            {
              type:"lead",
              text:"Jesus have mercy on us.", 
              audio:"",
            },
            {
              type:"response",
              text:"Mother Mary help us.",
              audio:"",
            }
          ],
        },
        'hail_holy_queen':{
          title:"Hail Holy Queen",
          prayerSections:[
            {
              type:"lead",
              text:"Hail Holy Queen.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/01_Lead.mp3",
            },
            {
              type:"response",
              text:"Mother of mercy, hail, our life, our sweetness " +
              "and our hope. To thee do we cry, poor banished children of Eve: to " +
              "thee do we send up our sighs, mourning and weeping in this valley " +
              "of tears. Turn then, most gracious Advocate, thine eyes of mercy " +
              "toward us, and after this our exile, show unto us the blessed fruit " +
              "of thy womb, Jesus, O clement, O loving, O sweet Virgin Mary.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/02_Response.mp3",
            },
            {
              type:"lead",
              text:"Pray for us o Holy mother of God.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/03_Lead.mp3",
            },
            {
              type:"response",
              text:"That we may be made worthy of the promises of Christ.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/04_Response.mp3",
            },
            {
              type:"lead",
              text:"Let us pray.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/05_Lead.mp3",
            },
            {
              type:"response",
              text:"O God, whose only begotten Son, by his life, death and " +
              "resurrection, has purchased for us the rewards of eternal salvation, " +
              "grant we beseech thee, that meditating upon these mysteries in the " +
              "most holy rosary of the blessed Virgin Mary, we may imitate what they " +
              "contain and obtain what they promise through the same Christ our " +
              "Lord. Amen.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Hail+Holy+Queen/06_Response.mp3",
            }
          ],
        },
        'sacred_heart_of_jesus':{
          title:"Sacred Heart of Jesus",
          prayerSections:[
            {
              type:"lead",
              text:"Sacred heart of Jesus.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/01_Lead.mp3",
            },
            {
              type:"response",
              text:"Have mercy on us.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/02_Response.mp3",
            },
            {
              type:"lead",
              text:"Immaculate heart of Mary.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/03_Lead.mp3",
            },
            {
              type:"response",
              text:"Pray for us.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/04_Response.mp3",
            },
            {
              type:"lead",
              text:"St. Joseph", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/05_Lead.mp3",
            },
            {
              type:"response",
              text:"Pray for us.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/06_Response.mp3",
            },
            {
              type:"lead",
              text:"St. John the Evangelist.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/07_Lead.mp3",
            },
            {
              type:"response",
              text:"Pray for us.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/08_Response.mp3",
            },
            {
              type:"lead",
              text:"St. Louis-Maria de Montfront.", 
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/09_Lead.mp3",
            },
            {
              type:"response",
              text:"Pray for us.",
              audio:"https://digital-prayers.s3.us-west-2.amazonaws.com/Voice/Sacred+Heart+of+Jesus/10_Response.mp3",
            }
          ],
        },
      } 
    )

  const getMysteryPositionIndex = (rosaryPosition) => {

    let mysteryPositionIndex =  Object.keys(mysteryPositions).find(mp => mysteryPositions[mp].some(item => item == rosaryPosition))

    return mysteryPositionIndex ? mysteryPositionIndex : 0;
  }

  console.log(getMysteryPositionIndex("4-2"))

  const getMysteryByDay = (day) =>
  {
    let mysteryKey = Object.keys(mystries).find(key => mystries[key].days.some(d => d == day))

    return mysteryKey;
  }

  const dayOfWeek = getDayOfWeek();

  const mysterySeriesKey = getMysteryByDay(dayOfWeek.day);

  const getComment = (mysteryIndex) =>
  {

    let mystery = mystries[mysterySeriesKey];
    let postionsLabel = ["First", "Second", "Third", "Fourth", "Fifth"]

    return {
      title:mystery.title,
      prayerSections:[
        {
          type:"lead",
          text:  mystery.title + ". " + postionsLabel[mysteryIndex-1] + ", " + mystery.mystries[mysteryIndex-1].title,
          audio: mystery.mystries[mysteryIndex-1].audio,
        },
      ],
    }
  }

  const getPrayer = (prayerIndexKey) =>{

    switch(prayerIndexKey)
    {
      case "first_mystery":
        return getComment(1);
      case "second_mystery":
        return getComment(2);
      case "third_mystery":
        return getComment(3);
      case "fourth_mystery":
        return getComment(4);
      case "fifth_mystery":
        return getComment(5);
      default:
        return prayers[prayerIndexKey]
    }  
  }

  const RenderPrayer = () => {

    //currentRosaryPositionRef.current = currentRosaryPosition;
    //currentRosaryPrayerPostionRef.current = currentRosaryPrayerPostion;

    let prayerKey = rosaryState[rosaryPositions[currentRosaryPositionRef.current]][currentRosaryPrayerPostionRef.current]
    let prayer = getPrayer(prayerKey);

    //console.log({prayer,  prayerKey, rp:rosaryPositions[currentRosaryPositionRef.current], cr: currentRosaryPositionRef.current, crp: currentRosaryPrayerPostionRef.current})
    return(
      
      <div
      style={{
        background:'none',
        border:'0px solid #CCCCCC',
        minHeight:80,
        width:'100%', 
        height:'98%',        
        display:'flex',
        flexDirection:'column',
        borderRadius:5,              
      }}
    >
      <div style={{fontSize:18, fontFamily:'Marcellus SC', borderRadius:'5px 5px 0px 0px', background:'linear-gradient(135deg, rgb(15, 32, 39), rgb(32, 58, 67), rgb(44, 83, 100))', padding:10, borderBottom:'0px solid #CCCCCC', color:'white', fontWeight:'normal',}}>
        {prayer.title}
      </div>
      <div style={{ padding:10, flexGrow:1, fontFamily:'Red Hat Display', lineHeight:'1.5', fontSize:18, overflowY:'auto', background:'#ffffff', boxShadow:'inset 1px 1px 5px rgba(100,100,100,0.4)', borderRadius:'0px 0px 5px 5px', }}>
    {prayer.prayerSections.map((ps, indx) => 

      <div key={indx} style={{color:`${ps.type == "lead" ? "red" : "black"}`}}>
        <span style={{fontWeight:'bold'}}>{ps.type == "lead" ? "Lead" : "Response"}{": "}</span><span style={{fontWeight:'normal'}}>{ps.text}</span>
      </div>

    )}
      </div>
    </div>
    )
  }

  const onLoad = (loadedAudios) => {
      setAudios(loadedAudios);
      console.log(loadedAudios)
  }

  const formatPrayerIndex = (indexString) =>
  {
    if(indexString === "cross" || indexString === "end")
    {
       return 1;
    }
    else{
      let indexParts = indexString.split("-");
      if(indexParts[1] === "P" || indexParts[1] === 0 || indexParts[1] === "0")
        return indexParts[0] + "-1";

      return indexParts[0] + "-" + indexParts[1];
    }

   

  }

  return (
    <PreloaderView onLoad={onLoad} audios={preloadedResources?.audios}>
      <div style={{display:'flex', height:'100vh', alignItems:'center', 
      background:`url(${background01})`,
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat',
      flexGrow:1, flexDirection:'column'}}>
       
       <div
          style={{
            background:'black',
            //border:'1px solid #CCCCCC',
            color:'white',
            minHeight:80,
            width:'100%', 
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            padding:20          
          }}
        >
          <div>
            <div style={{fontSize:24, fontFamily:'Marcellus SC', fontWeight:'bold'}}>
            {mystries[mysterySeriesKey].title}
            </div>
            <div style={{fontSize:16, fontWeight:'normal'}}>
              {mystries[mysterySeriesKey].mystries[mysteryPosition -1]?.title}
            </div>
          </div>
          <div style={{fontSize:20, color:'red', fontFamily:'Dancing Script', fontWeight:'bold'}}>
            {dayOfWeek.label}
          </div>
          
        </div>
        <div
          style={{
            background:'linear-gradient(to right, rgb(138, 35, 135), rgb(233, 64, 87), rgb(242, 113, 33))',
            border:'0px solid #CCCCCC',
            //minHeight:5,
            height:5,
            display:'flex',
            justifyContent:'center',
            width:'100%',             
            padding:0          
          }}
        ><div style={{background:'#333333', height:5, width: `${Sounds ? pos/Sounds.duration() *100: 0}%` }}></div>{/* <input onChange={setSeek} type={'range'} value={pos} max={Sounds?.duration()} min={0} /> */}</div>
        <div
          style={{
            background:'rgba(255,255,255,0.7)',
            border:'0px solid #CCCCCC',
            //minHeight:10,
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            width:'90%',
            maxWidth:600,
            margin:10,
            boxShadow:'2px 2px 5px rgba(100,100,100,0.2)', 
            borderRadius:5,
            border:'1px solid #CECECE',            
            padding:0          
          }}
        >
          <div style={{display:'flex', padding:5, background:'#fff6e7', borderRadius:'5px 5px 0px 0px', alignItems:'center', width:'100%', paddingBottom:5, borderBottom:'1px solid #cecece', justifyContent:'left'}}>
          {isAudioPlaying && <SoundLoader color="#ff9900" height="16" width="60" />}
          <span style={{fontWeight:'bold', fontSize:13}}>
            {`${formatPrayerIndex(rosaryPositions[currentRosaryPositionRef.current])}. `}
            {getPrayer(rosaryState[rosaryPositions[currentRosaryPositionRef.current]][currentRosaryPrayerPostionRef.current])?.title}
          </span>
          </div>
          <div  style={{
            background:'none',
            border:'0px solid #CCCCCC',
            //minHeight:10,

            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            width:'100%',             
            padding:5,
            //paddingTop:20,         
          }}>
          <button onClick={navigateToPrevious} style={{fontSize:20,  outline:'none', margin:10, padding:'2px 6px', border:'1px solid #CECECE', borderRadius:'16px 0px 0px 16px', borderRadius:'16px', height:32, width:32, display:'flex', justifyContent:'center', alignItems:'center', /* boxShadow:'2px 2px 5px rgba(100,100,100,0.4)',  */fontWeight:'bold'}}>
            <img width={16} src={previous}/>
          </button>
          {/* <button style={{fontSize:20, padding:2, display:'flex', border:'1px solid #999999', fontWeight:'bold'}}>
            <img width={20}  src={stop}/>
          </button> */}
          <button onClick={isAudioPlaying ? pause : isAudioPaused ? continueAudio : play} style={{fontSize:20, outline:'none', background:'linear-gradient(135deg, rgb(15, 32, 39), rgb(32, 58, 67), rgb(44, 83, 100))', padding:2, display:'flex', border:'1px solid #CECECE', borderRadius:'8px', height:48, width:48, display:'flex', justifyContent:'center', alignItems:'center',/*  boxShadow:'2px 2px 5px rgba(100,100,100,0.4)',  */fontWeight:'bold'}}>
            <img width={20}  src={isAudioPlaying ? pauseIcon : playIcon}/>
          </button>
          <button onClick={navigateToNext} style={{fontSize:20, outline:'none', margin:10, padding:'2px 6px', border:'1px solid #CECECE', borderRadius:'0px 16px 16px 0px', borderRadius:'16px', height:32, width:32, display:'flex', justifyContent:'center', alignItems:'center', /* boxShadow:'2px 2px 5px rgba(100,100,100,0.4)', */ fontWeight:'bold'}}>
            <img width={16}  src={next}/>
          </button>
          
          </div>

          <div  style={{
            background:'none',
            border:'0px solid #CCCCCC',
            //minHeight:10,

            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            width:'100%',             
            padding:2,
            paddingTop:2,         
          }}>
          
          <span onClick={()=>updateAudioRate(0.9)} className= {audioRate === 0.9 ? 'selected-audio-speed-button': 'audio-speed-button'}>x0.9</span>
          <span onClick={()=>updateAudioRate(1.0)} className= {audioRate === 1.0 ? 'selected-audio-speed-button': 'audio-speed-button'}>x1.0</span>
          <span onClick={()=>updateAudioRate(1.1)} className= {audioRate === 1.1 ? 'selected-audio-speed-button': 'audio-speed-button'}>x1.1</span>
          <span onClick={()=>updateAudioRate(1.2)} className= {audioRate === 1.2 ? 'selected-audio-speed-button': 'audio-speed-button'}>x1.2</span>
          
          </div>
          
        </div>
        <div style={{
          display:'flex',
          height:'80%',
          width:'90%',
          maxWidth:600,
          alignItems:'flex-start',
          flexGrow:1,
          borderRadius:'5px',
          overflowY:'auto',
          background:'rgba(255, 255, 255, 0.7)',
          padding:10,
          margin:20,
          boxSizing:'border-box',
        }}>
            <Rosary rosarySteps={rosaryPositions} currentStep={{stepId:rosaryPositions[currentRosaryPosition], stepIndex:currentRosaryPosition}}/>
          </div>
        <div
          style={{
            //background:'linear-gradient(0deg, rgb(0 0 0), rgb(255 254 255 / 0%))',
            border:'0px solid #CCCCCC',
            minHeight:'35vh',
            maxHeight:'35vh',
            height:'35vh',
            width:'90%', 
            padding:0,
            paddingBottom:10,
            //position:'fixed', 
            bottom:0,
            display:'flex',
            justifyContent:'center'     
          }}
        >
          <div style={{display:'flex', width:'100%', position:'relative', maxWidth:600}}>
            {/*RenderPrayer(prayers["hail_holy_queen"])*/}
            {/* <PullRelease padding={10} >
              {RenderPrayer()}
            </PullRelease> */}
            {RenderPrayer()}
            
            {/*//rosaryState[rosaryPositions[currentRosaryPosition]][currentRosaryPrayerPostion])}*/}
          </div>
        
        </div>
       
      </div>
      </PreloaderView>
    
  );
}

export default App;
