import React, {useEffect} from 'react';
import useImagePreloader from '../hooks/useImagePreloader';
import useAudioPreloader from '../hooks/useAudioPreloader';
import {ThreeDots as Loader} from 'react-loader-spinner'; 


const getResourcesLength = (images, audios) => {
    if(images && audios && Array.isArray(images) && Array.isArray(audios))
    {
        return images.length + audios.length;
    }
    else if( Array.isArray(images) )
    {
        return images.length;
    }
    else if( Array.isArray(audios) )
    {
        return audios.length;
    }
    else
    {
        return 0
    }

}

const PreloaderView = (props) => {
    // Callback to return a list of loaded Howls
    const [audioIsReady, audiosReadyCnt, audios] = useAudioPreloader(props.audios);
    
    // Callback to return a list of loaded images
    const [imageIsReady,imagesReadyCnt] = useImagePreloader(props.images);

    const totalResourcesCount = getResourcesLength(props.images, props.audios);

    /*useEffect(()=>{
        props.setAudios && props.setAudios(audios)
    },[audioIsReady])*/

    useEffect(()=>{
        if(audioIsReady && imageIsReady)
        {            
            props.onLoad && props.onLoad(audios)
        }

    },[audioIsReady, imageIsReady])

    //
    return ( (imageIsReady && audioIsReady) || totalResourcesCount === 0 ? props.children : <> 
    <div style={{minHeight:300,  display:'flex', flexDirection:'column', alignItems:'center',
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
    
    justifyContent:'center', width:'100%', height:'100vh', boxSizing:'border-box', position:'relative',  background:'linear-gradient(135deg, rgb(15, 32, 39), rgb(32, 58, 67), rgb(44, 83, 100))',
    backgroundClip:'text', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent',
    }}>
        {/*<div style={{fontSize:14, fontWeight:'bold', color:'#444444'}}>
        Loading game data...{`${Math.ceil((imagesReadyCnt+audiosReadyCnt)/totalResourcesCount *100)}%`}
        </div>*/}
        <div style={{fontSize:50, color:'white', fontFamily:'Dancing Script', fontWeight:'bold'}}>
            {"Holy Rosary"}
        </div>
        <div style={{justifyContent:'center', display:'flex', flexDirection:'column', padding:30}}><Loader type="ThreeDots" color="#ff9900" height="10" width="60" /></div>
        <div style={{fontSize:13, color:'grey', fontFamily:'arial', fontWeight:'bold'}}>
            {"Loading resources..."}
        </div>
        <span style={{fontSize:20, color:'#ffffff', padding:16}}>{`${Math.ceil((imagesReadyCnt+audiosReadyCnt)/totalResourcesCount *100)}%`}</span>
       
        <div style={{height:8, width:150, position:'relative', border:'1px solid #ff9900', margin:10, borderRadius:4, backgroundColor:'#ffffff'}}>
            <div style={{height:6,width:`${Math.ceil((imagesReadyCnt+audiosReadyCnt)/totalResourcesCount *100)}%`, borderRadius:3, backgroundColor:'#ff9900'}}></div>
        </div>
        </div> 
        </>
    
    

    )
}

export default PreloaderView;