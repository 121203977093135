import React, { useEffect } from 'react'


// rosaryState,
// rosarySteps,
// currentStepIndex,
// currentStep : { stepId, stepIndex}
const Rosary = (props) => {

    const [stepStateArray, setStepStateArray] = React.useState([]);
    const [currentStep, setCurrentStep] = React.useState(null);
    const [rosarySteps, setRosarySteps] = React.useState([]);

    useEffect(()=>{
        //setStepStateArray([...props.rosaryState]);
        setCurrentStep({...props.currentStep});
        setRosarySteps([...props.rosarySteps])

    }, [props.rosaryState, props.currentStep, props.rosarySteps])

    const stepMouseDown = (e, stepId) =>
    {

    }

    const getStepStyle = (stepId) => {

        if(!currentStep) return {}

        let index = rosarySteps.findIndex(rs => rs == stepId);

        // is current
        let isCurrentStep = stepId == currentStep.stepId;

        // is past step
        let isPastStep = index < currentStep.stepIndex;

        // default (future step)

        if(isCurrentStep)
        {
            return { strokeWidth:5, stroke:'green',  opacity:1}
        }
        else if (isPastStep)
        {
            return {stroke:1, opacity:0.5}
        }
        else
        {
            return {stroke:1, opacity:1.0}
        }
        
    }

    return(  <>  

    {/* <div style={{position:'absolute', color:'white', borderRadius:3, padding:'4px 10px', fontSize:10, background:'black'}}>
        Our Father
    </div>  */} 
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
             width="800px" height="1200px" viewBox="0 0 800 1200" enableBackground="new 0 0 800 1200" >
        <path fill="none" stroke="#754C29" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" d="M375.029,764.461
            c-29.456-42.936-69.589-86.668-110.131-127.627c-39.166-39.568-72.262-84.478-104.257-129.891
            c-39.993-56.765-79.311-114.555-113.429-175.08c-32.824-58.23-36.568-159.909,1.89-221.377c48.854-78.086,137.229-93.749,209-46.854
            c42.742,27.927,111.083,126.854,140.083,126.854"/>
        <radialGradient id="SVGID_1_" cx="1126.542" cy="418.5996" r="9.0969" fx="1127.6547" fy="418.4585" gradientTransform="matrix(-1.9946 0 0 1.9946 2349.1333 -425.8553)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_1_)" style={{...getStepStyle("5-0")}} onMouseDown={(e)=>stepMouseDown(e,"5-0")} cx="98.03" cy="415.923" r="18.145"/>
        <radialGradient id="SVGID_2_" cx="1079.415" cy="231.5986" r="9.0969" fx="1080.5277" fy="231.4575" gradientTransform="matrix(-1.9946 0 0 1.9946 2349.1333 -425.8553)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_2_)"  style={{...getStepStyle("4-0")}} onMouseDown={(e)=>stepMouseDown(e,"4-0")}  cx="192.03" cy="42.923" r="18.145"/>
        <radialGradient id="SVGID_3_" cx="2421.9697" cy="717.9575" r="9.097" fx="2423.0823" fy="717.8163" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_3_)" style={{...getStepStyle("5-10")}} onMouseDown={(e)=>stepMouseDown(e,"5-10")} cx="343.03" cy="720.56" r="9.073"/>
        <circle fill="none" style={{...getStepStyle("end")}} onMouseDown={(e)=>stepMouseDown(e,"end")} cx="363.03" cy="747.56" r="9.073"></circle>
        {/* <radialGradient id="SVGID_4_" cx="2401.917" cy="744.0278" r="9.0967" fx="2403.0295" fy="743.8867" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_4_)" cx="363.03" cy="746.56" r="9.073"/> */}
        <radialGradient id="SVGID_5_" cx="2448.04" cy="688.8794" r="9.0974" fx="2449.1528" fy="688.7382" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_5_)" style={{...getStepStyle("5-9")}} onMouseDown={(e)=>stepMouseDown(e,"5-9")} cx="317.03" cy="691.56" r="9.073"/>
        <radialGradient id="SVGID_6_" cx="2472.1045" cy="661.8071" r="9.097" fx="2473.217" fy="661.666" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_6_)" style={{...getStepStyle("5-8")}} onMouseDown={(e)=>stepMouseDown(e,"5-8")} cx="293.03" cy="664.56" r="9.073"/>
        <radialGradient id="SVGID_7_" cx="2500.1807" cy="633.731" r="9.0971" fx="2501.2932" fy="633.5898" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_7_)" style={{...getStepStyle("5-7")}} onMouseDown={(e)=>stepMouseDown(e,"5-7")} cx="265.03" cy="636.56" r="9.073"/>
        <radialGradient id="SVGID_8_" cx="2525.2471" cy="607.6626" r="9.097" fx="2526.3596" fy="607.5214" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_8_)" style={{...getStepStyle("5-6")}} onMouseDown={(e)=>stepMouseDown(e,"5-6")} cx="240.03" cy="610.56" r="9.073"/>
        <radialGradient id="SVGID_9_" cx="2549.3115" cy="579.5864" r="9.0974" fx="2550.4243" fy="579.4453" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_9_)" style={{...getStepStyle("5-5")}} onMouseDown={(e)=>stepMouseDown(e,"5-5")} cx="216.03" cy="582.56" r="9.073"/>
        <radialGradient id="SVGID_10_" cx="2570.3682" cy="550.5083" r="9.0971" fx="2571.4807" fy="550.3671" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_10_)" style={{...getStepStyle("5-4")}} onMouseDown={(e)=>stepMouseDown(e,"5-4")} cx="195.03" cy="553.56" r="9.073"/>
        <radialGradient id="SVGID_11_" cx="2591.4248" cy="524.4385" r="9.0971" fx="2592.5374" fy="524.2973" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_11_)" style={{...getStepStyle("5-3")}} onMouseDown={(e)=>stepMouseDown(e,"5-3")} cx="174.03" cy="527.56" r="9.073"/>
        <radialGradient id="SVGID_12_" cx="2609.4736" cy="496.3633" r="9.0971" fx="2610.5862" fy="496.2221" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_12_)" style={{...getStepStyle("5-2")}} onMouseDown={(e)=>stepMouseDown(e,"5-2")} cx="156.03" cy="499.56" r="9.073"/>
        <radialGradient id="SVGID_13_" cx="2630.5283" cy="468.2881" r="9.0974" fx="2631.6411" fy="468.1469" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_13_)" style={{...getStepStyle("5-1")}} onMouseDown={(e)=>stepMouseDown(e,"5-1")} cx="135.03" cy="471.56" r="9.073"/>
        <radialGradient id="SVGID_14_" cx="2704.7275" cy="351.9766" r="9.0974" fx="2705.8403" fy="351.8354" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_14_)" style={{...getStepStyle("4-10")}} onMouseDown={(e)=>stepMouseDown(e,"4-10")} cx="61.03" cy="355.56" r="9.073"/>
        <circle fill="none" style={{...getStepStyle("5-P")}} onMouseDown={(e)=>stepMouseDown(e,"5-P")} cx="75.03" cy="378.56" r="9.073"></circle>
        <radialGradient id="SVGID_15_" cx="2725.7842" cy="317.8853" r="9.0974" fx="2726.897" fy="317.7441" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_15_)" style={{...getStepStyle("4-9")}} onMouseDown={(e)=>stepMouseDown(e,"4-9")} cx="40.03" cy="321.56" r="9.073"/>
        <radialGradient id="SVGID_16_" cx="2739.8213" cy="283.7939" r="9.0974" fx="2740.9341" fy="283.6528" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_16_)" style={{...getStepStyle("4-8")}} onMouseDown={(e)=>stepMouseDown(e,"4-8")} cx="26.03" cy="287.56" r="9.073"/>
        <radialGradient id="SVGID_17_" cx="2743.833" cy="246.6948" r="9.0971" fx="2744.9456" fy="246.5537" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_17_)" style={{...getStepStyle("4-7")}} onMouseDown={(e)=>stepMouseDown(e,"4-7")} cx="22.03" cy="250.56" r="9.073"/>
        <radialGradient id="SVGID_18_" cx="2744.835" cy="209.5952" r="9.0974" fx="2745.9478" fy="209.454" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_18_)" style={{...getStepStyle("4-6")}} onMouseDown={(e)=>stepMouseDown(e,"4-6")} cx="21.03" cy="213.56" r="9.073"/>
        <radialGradient id="SVGID_19_" cx="2741.8271" cy="169.4878" r="9.0974" fx="2742.9399" fy="169.3466" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_19_)" style={{...getStepStyle("4-5")}} onMouseDown={(e)=>stepMouseDown(e,"4-5")} cx="24.03" cy="173.56" r="9.073"/>
        <radialGradient id="SVGID_20_" cx="2730.7979" cy="132.3887" r="9.0971" fx="2731.9104" fy="132.2475" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_20_)" style={{...getStepStyle("4-4")}} onMouseDown={(e)=>stepMouseDown(e,"4-4")} cx="35.03" cy="136.56" r="9.073"/>
        <radialGradient id="SVGID_21_" cx="2707.7354" cy="96.292" r="9.0974" fx="2708.8481" fy="96.1508" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_21_)" style={{...getStepStyle("4-3")}} onMouseDown={(e)=>stepMouseDown(e,"4-3")} cx="58.03" cy="100.56" r="9.073"/>
        <radialGradient id="SVGID_22_" cx="2678.6572" cy="63.2031" r="9.0974" fx="2679.77" fy="63.062" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_22_)" style={{...getStepStyle("4-2")}} onMouseDown={(e)=>stepMouseDown(e,"4-2")} cx="87.03" cy="67.56" r="9.073"/>
        <radialGradient id="SVGID_23_" cx="2637.5479" cy="38.1362" r="9.0974" fx="2638.6606" fy="37.9951" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_23_)" style={{...getStepStyle("4-1")}} onMouseDown={(e)=>stepMouseDown(e,"4-1")} cx="128.03" cy="42.56" r="9.073"/>
        <radialGradient id="SVGID_24_" cx="2512.2119" cy="54.1792" r="9.0974" fx="2513.3247" fy="54.038" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_24_)" style={{...getStepStyle("3-10")}} onMouseDown={(e)=>stepMouseDown(e,"3-10")} cx="253.03" cy="58.56" r="9.073"/>
        <circle fill="none" style={{...getStepStyle("4-P")}} onMouseDown={(e)=>stepMouseDown(e,"4-P")} cx="225.03" cy="45.56" r="9.073"></circle>
        <radialGradient id="SVGID_25_" cx="2478.1201" cy="83.2568" r="9.0974" fx="2479.2329" fy="83.1157" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_25_)" style={{...getStepStyle("3-9")}} onMouseDown={(e)=>stepMouseDown(e,"3-9")} cx="287.03" cy="87.56" r="9.073"/>
        <radialGradient id="SVGID_26_" cx="2447.0381" cy="114.3403" r="9.0971" fx="2448.1506" fy="114.1992" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_26_)" style={{...getStepStyle("3-8")}} onMouseDown={(e)=>stepMouseDown(e,"3-8")} cx="318.03" cy="118.56" r="9.073"/>
        <radialGradient id="SVGID_27_" cx="2416.958" cy="148.4316" r="9.0971" fx="2418.0706" fy="148.2905" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_27_)" style={{...getStepStyle("3-7")}} onMouseDown={(e)=>stepMouseDown(e,"3-7")} cx="348.03" cy="152.56" r="9.073"/>
        <radialGradient id="SVGID_28_" cx="2382.8662" cy="178.5122" r="9.0971" fx="2383.9788" fy="178.371" gradientTransform="matrix(-0.9973 0 0 0.9973 2760.5427 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_28_)" style={{...getStepStyle("3-6")}} onMouseDown={(e)=>stepMouseDown(e,"3-6")} cx="382.03" cy="182.56" r="9.073"/>
        <path fill="none" stroke="#754C29" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" d="M421.113,764.461
            c29.456-42.936,72.589-86.668,113.131-127.627c39.166-39.568,72.262-84.478,104.257-129.891
            c39.993-56.765,79.311-114.555,113.429-175.08c32.824-58.23,36.568-159.909-1.89-221.377c-48.854-78.086-137.229-93.749-209-46.854
            C498.298,91.56,429.957,190.487,400.957,190.487"/>
        <radialGradient id="SVGID_29_" cx="-1033.7451" cy="418.5996" r="9.0969" fx="-1032.6324" fy="418.4585" gradientTransform="matrix(1.9946 0 0 1.9946 2759.0093 -425.8553)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_29_)" style={{...getStepStyle("2-0")}} onMouseDown={(e)=>stepMouseDown(e,"2-0")} cx="701.113" cy="415.923" r="18.145"/>
        <radialGradient id="SVGID_30_" cx="-1080.8711" cy="231.5986" r="9.0969" fx="-1079.7584" fy="231.4575" gradientTransform="matrix(1.9946 0 0 1.9946 2759.0093 -425.8553)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_30_)" style={{...getStepStyle("3-0")}} onMouseDown={(e)=>stepMouseDown(e,"3-0")}  cx="607.113" cy="40.923" r="18.145"/>
        <radialGradient id="SVGID_31_" cx="-1898.6006" cy="717.9575" r="9.0968" fx="-1897.4879" fy="717.8163" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_31_)" style={{...getStepStyle("1-1")}} onMouseDown={(e)=>stepMouseDown(e,"1-1")} cx="456.113" cy="720.56" r="9.073"/>
       {/*  <radialGradient id="SVGID_32_" cx="-1920.6597" cy="743.0239" r="9.0972" fx="-1919.547" fy="742.8828" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_32_)" cx="434.113" cy="745.56" r="9.073"/> */}
        <radialGradient id="SVGID_33_" cx="-1873.5337" cy="686.8735" r="9.0972" fx="-1872.421" fy="686.7324" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_33_)" style={{...getStepStyle("1-2")}} onMouseDown={(e)=>stepMouseDown(e,"1-2")} cx="481.113" cy="689.56" r="9.073"/>
        <radialGradient id="SVGID_34_" cx="-1849.4692" cy="660.8052" r="9.0968" fx="-1848.3566" fy="660.664" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_34_)" style={{...getStepStyle("1-3")}} onMouseDown={(e)=>stepMouseDown(e,"1-3")} cx="505.113" cy="663.56" r="9.073"/>
        <radialGradient id="SVGID_35_" cx="-1820.3911" cy="633.731" r="9.0972" fx="-1819.2784" fy="633.5898" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_35_)" style={{...getStepStyle("1-4")}} onMouseDown={(e)=>stepMouseDown(e,"1-4")} cx="534.113" cy="636.56" r="9.073"/>
        <radialGradient id="SVGID_36_" cx="-1795.3242" cy="607.6626" r="9.0968" fx="-1794.2115" fy="607.5214" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_36_)" style={{...getStepStyle("1-5")}} onMouseDown={(e)=>stepMouseDown(e,"1-5")} cx="559.113" cy="610.56" r="9.073"/>
        <radialGradient id="SVGID_37_" cx="-1771.2598" cy="579.5864" r="9.0972" fx="-1770.1471" fy="579.4453" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_37_)" style={{...getStepStyle("1-6")}} onMouseDown={(e)=>stepMouseDown(e,"1-6")} cx="583.113" cy="582.56" r="9.073"/>
        <radialGradient id="SVGID_38_" cx="-1750.2031" cy="550.5083" r="9.0972" fx="-1749.0905" fy="550.3671" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_38_)" style={{...getStepStyle("1-7")}} onMouseDown={(e)=>stepMouseDown(e,"1-7")}  cx="604.113" cy="553.56" r="9.073"/>
        <radialGradient id="SVGID_39_" cx="-1729.147" cy="524.4385" r="9.0972" fx="-1728.0343" fy="524.2973" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_39_)" style={{...getStepStyle("1-8")}} onMouseDown={(e)=>stepMouseDown(e,"1-8")} cx="625.113" cy="527.56" r="9.073"/>
        <radialGradient id="SVGID_40_" cx="-1711.0986" cy="496.3633" r="9.0972" fx="-1709.986" fy="496.2221" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_40_)" style={{...getStepStyle("1-9")}} onMouseDown={(e)=>stepMouseDown(e,"1-9")} cx="643.113" cy="499.56" r="9.073"/>
        <radialGradient id="SVGID_41_" cx="-1690.042" cy="468.2881" r="9.0972" fx="-1688.9293" fy="468.1469" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_41_)" style={{...getStepStyle("1-10")}} onMouseDown={(e)=>stepMouseDown(e,"1-10")} cx="664.113" cy="471.56" r="9.073"/>
        <circle fill="none" style={{...getStepStyle("2-P")}} onMouseDown={(e)=>stepMouseDown(e,"2-P")}  cx="680.113" cy="445.56" r="9.073"></circle>
        <radialGradient id="SVGID_42_" cx="-1615.8433" cy="351.9766" r="9.0972" fx="-1614.7306" fy="351.8354" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_42_)" style={{...getStepStyle("2-1")}} onMouseDown={(e)=>stepMouseDown(e,"2-1")}  cx="738.113" cy="355.56" r="9.073"/>
        <radialGradient id="SVGID_43_" cx="-1594.7871" cy="317.8853" r="9.0972" fx="-1593.6744" fy="317.7441" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_43_)" style={{...getStepStyle("2-2")}} onMouseDown={(e)=>stepMouseDown(e,"2-2")} cx="759.113" cy="321.56" r="9.073"/>
        <radialGradient id="SVGID_44_" cx="-1580.7495" cy="283.7939" r="9.0972" fx="-1579.6368" fy="283.6528" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_44_)" style={{...getStepStyle("2-3")}} onMouseDown={(e)=>stepMouseDown(e,"2-3")} cx="770.113" cy="287.56" r="9.073"/>
        <radialGradient id="SVGID_45_" cx="-1576.7388" cy="246.6948" r="9.0972" fx="-1575.6261" fy="246.5537" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_45_)" style={{...getStepStyle("2-4")}} onMouseDown={(e)=>stepMouseDown(e,"2-4")} cx="777.113" cy="250.56" r="9.073"/>
        <radialGradient id="SVGID_46_" cx="-1575.7358" cy="209.5952" r="9.0972" fx="-1574.6232" fy="209.4541" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_46_)" style={{...getStepStyle("2-5")}} onMouseDown={(e)=>stepMouseDown(e,"2-5")} cx="778.113" cy="213.56" r="9.073"/>
        <radialGradient id="SVGID_47_" cx="-1578.7441" cy="169.4878" r="9.0972" fx="-1577.6315" fy="169.3466" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_47_)" style={{...getStepStyle("2-6")}} onMouseDown={(e)=>stepMouseDown(e,"2-6")} cx="775.113" cy="173.56" r="9.073"/>
        <radialGradient id="SVGID_48_" cx="-1589.7734" cy="132.3887" r="9.0972" fx="-1588.6608" fy="132.2475" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_48_)" style={{...getStepStyle("2-7")}} onMouseDown={(e)=>stepMouseDown(e,"2-7")} cx="764.113" cy="136.56" r="9.073"/>
        <radialGradient id="SVGID_49_" cx="-1612.8354" cy="96.292" r="9.0972" fx="-1611.7228" fy="96.1508" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_49_)" style={{...getStepStyle("2-8")}} onMouseDown={(e)=>stepMouseDown(e,"2-8")} cx="741.113" cy="100.56" r="9.073"/>
        <radialGradient id="SVGID_50_" cx="-1641.9131" cy="63.2031" r="9.0972" fx="-1640.8004" fy="63.062" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_50_)" style={{...getStepStyle("2-9")}} onMouseDown={(e)=>stepMouseDown(e,"2-9")} cx="712.113" cy="67.56" r="9.073"/>
        
        <radialGradient id="SVGID_51_" cx="-1683.0234" cy="38.1362" r="9.0972" fx="-1681.9108" fy="37.9951" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="none" style={{...getStepStyle("3-P")}} onMouseDown={(e)=>stepMouseDown(e,"3-P")} cx="641.113" cy="37.56" r="9.073"></circle>
        <circle fill="url(#SVGID_51_)" style={{...getStepStyle("2-10")}} onMouseDown={(e)=>stepMouseDown(e,"2-10")} cx="671.113" cy="42.56" r="9.073"/>
        <radialGradient id="SVGID_52_" cx="-1808.3589" cy="54.1792" r="9.0972" fx="-1807.2462" fy="54.038" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_52_)" style={{...getStepStyle("3-1")}} onMouseDown={(e)=>stepMouseDown(e,"3-1")} cx="546.113" cy="58.56" r="9.073"/>
        <radialGradient id="SVGID_53_" cx="-1842.4502" cy="83.2568" r="9.0972" fx="-1841.3375" fy="83.1157" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_53_)" style={{...getStepStyle("3-2")}} onMouseDown={(e)=>stepMouseDown(e,"3-2")} cx="512.113" cy="87.56" r="9.073"/>
        <radialGradient id="SVGID_54_" cx="-1873.5337" cy="114.3403" r="9.0972" fx="-1872.421" fy="114.1992" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_54_)" style={{...getStepStyle("3-3")}} onMouseDown={(e)=>stepMouseDown(e,"3-3")} cx="481.113" cy="118.56" r="9.073"/>
        <radialGradient id="SVGID_55_" cx="-1903.6143" cy="148.4316" r="9.0972" fx="-1902.5016" fy="148.2905" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_55_)" style={{...getStepStyle("3-4")}} onMouseDown={(e)=>stepMouseDown(e,"3-4")} cx="451.113" cy="152.56" r="9.073"/>
        <radialGradient id="SVGID_56_" cx="-1937.7056" cy="178.5122" r="9.0972" fx="-1936.5929" fy="178.371" gradientTransform="matrix(0.9973 0 0 0.9973 2347.5999 1.1146)" gradientUnits="userSpaceOnUse">
        <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
        </radialGradient>
        <circle fill="url(#SVGID_56_)" style={{...getStepStyle("3-5")}} onMouseDown={(e)=>stepMouseDown(e,"3-5")} cx="417.113" cy="182.56" r="9.073"/>
        <g>
            <g>
                <path fill="#414042" d="M402.667,812.437c0-2.577-2.09-4.668-4.667-4.668c-2.578,0-4.668,2.091-4.668,4.668
                    c0,2.578,2.09,4.667,4.668,4.667C400.577,817.104,402.667,815.015,402.667,812.437z M401.346,812.437
                    c0,1.845-1.501,3.345-3.346,3.345s-3.347-1.5-3.347-3.345c0-1.846,1.502-3.346,3.347-3.346S401.346,810.591,401.346,812.437z"/>
                <rect x="396" y="804.18" fill="#414042" width="4" height="5"/>
            </g>
            <g>
                <path fill="#414042" d="M417.969,762.073c-1.822,1.822-1.822,4.778,0,6.601c1.822,1.823,4.778,1.822,6.601,0
                    c1.823-1.823,1.823-4.778,0-6.601C422.747,760.25,419.792,760.25,417.969,762.073z M418.903,763.006
                    c1.305-1.304,3.427-1.303,4.73,0.001c1.305,1.305,1.307,3.427,0.002,4.731c-1.306,1.306-3.428,1.305-4.732,0
                    S417.599,764.312,418.903,763.006z"/>
                
                    <rect x="415.198" y="766.944" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 168.123 1608.5265)" fill="#414042" width="4" height="4.999"/>
            </g>
            <g>
                <path fill="#414042" d="M371.384,762.073c-1.823,1.822-1.823,4.777,0,6.601c1.822,1.822,4.778,1.823,6.601,0
                    c1.822-1.822,1.822-4.778,0-6.601C376.161,760.25,373.206,760.25,371.384,762.073z M377.05,767.739
                    c-1.305,1.305-3.427,1.306-4.732,0c-1.305-1.305-1.303-3.427,0.002-4.731c1.304-1.304,3.426-1.305,4.73-0.001
                    C378.354,764.312,378.354,766.434,377.05,767.739z"/>
                
                    <rect x="376.755" y="766.944" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -433.1442 493.185)" fill="#414042" width="4" height="4.999"/>
            </g>
            <path fill="#414042" d="M398,1023.806c-2.578,0-4.668,2.089-4.668,4.668c0,2.576,2.09,4.667,4.668,4.667
                c2.577,0,4.667-2.091,4.667-4.667C402.667,1025.895,400.577,1023.806,398,1023.806z M398,1031.819c-1.845,0-3.347-1.5-3.347-3.345
                c0-1.846,1.502-3.346,3.347-3.346s3.346,1.5,3.346,3.346C401.346,1030.319,399.845,1031.819,398,1031.819z"/>
            <polygon fill="#808285" stroke="#414042"  style={{...getStepStyle("cross")}} onMouseDown={(e)=>stepMouseDown(e,"cross")}  strokeMiterlimit="10" points="434.5,1070.68 405.5,1070.68 405.5,1036.68 
                389.5,1036.68 389.5,1070.68 360.5,1070.68 360.5,1087.68 389.5,1087.68 389.5,1170.68 405.5,1170.68 405.5,1087.68 434.5,1087.68 
                    "/>
            
                <line fill="none" stroke="#754C29" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" x1="398" y1="1027.18" x2="398" y2="813.18"/>
            
                <radialGradient id="SVGID_57_" cx="-7.998" cy="706.8716" r="9.0972" fx="-6.8854" fy="706.7304" gradientTransform="matrix(1.9946 0 0 1.9946 410.1402 -425.8553)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
            </radialGradient>
            <circle fill="url(#SVGID_57_)" style={{...getStepStyle("0-0")}} onMouseDown={(e)=>stepMouseDown(e,"0-0")} cx="398.244" cy="995.923" r="18.145"/>
            
                <radialGradient id="SVGID_58_" cx="-7.998" cy="634.0962" r="9.0968" fx="-6.8854" fy="633.955" gradientTransform="matrix(1.9946 0 0 1.9946 410.1402 -425.8553)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
            </radialGradient>
            <circle fill="url(#SVGID_58_)" style={{...getStepStyle("1-0")}} onMouseDown={(e)=>stepMouseDown(e,"1-0")}  cx="398.244" cy="840.761" r="18.145"/>
            
                <radialGradient id="SVGID_59_" cx="398.5513" cy="943.561" r="9.0972" fx="399.6639" fy="943.4199" gradientTransform="matrix(0.9973 0 0 0.9973 -1.2688 1.1146)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
            </radialGradient>
            <circle fill="url(#SVGID_59_)" style={{...getStepStyle("0-1")}} onMouseDown={(e)=>stepMouseDown(e,"0-1")} cx="398.244" cy="945.56" r="9.073"/>
            
                <radialGradient id="SVGID_60_" cx="398.5513" cy="916.27" r="9.0972" fx="399.6639" fy="916.1288" gradientTransform="matrix(0.9973 0 0 0.9973 -1.2688 1.1146)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
            </radialGradient>
            <circle fill="url(#SVGID_60_)" style={{...getStepStyle("0-2")}} onMouseDown={(e)=>stepMouseDown(e,"0-2")} cx="398.244" cy="918.342" r="9.073"/>
            
                <radialGradient id="SVGID_61_" cx="398.5513" cy="888.979" r="9.0972" fx="399.6639" fy="888.8378" gradientTransform="matrix(0.9973 0 0 0.9973 -1.2688 1.1146)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
            </radialGradient>
            <circle fill="url(#SVGID_61_)" style={{...getStepStyle("0-3")}} onMouseDown={(e)=>stepMouseDown(e,"0-3")} cx="398.244" cy="891.125" r="9.073"/>
            <circle fill="none" style={{...getStepStyle("1-P")}} onMouseDown={(e)=>stepMouseDown(e,"1-P")} cx="398.244" cy="870.125" r="9.073"></circle>
            <rect x="396" y="1032.18" fill="#414042" width="4" height="4"/>
            <circle fill="#939598" stroke="#6D6E71" strokeMiterlimit="10" cx="398" cy="783.765" r="22.682"/>
        </g>
        </svg>
        </> 
    )

}
/*
const Rosary = (props) => {

    return(
        
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="800px" height="1200px" viewBox="0 0 800 1200" enableBackground="new 0 0 800 1200" >
<path fill="none" stroke="#754C29" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" d="M375.029,764.461
	c-29.456-42.936-69.589-86.668-110.131-127.627c-39.166-39.568-72.262-84.478-104.257-129.891
	c-39.993-56.765-79.311-114.555-113.429-175.08c-32.824-58.23-36.568-159.909,1.89-221.377c48.854-78.086,137.229-93.749,209-46.854
	c42.742,27.927,111.083,126.854,140.083,126.854"/>
<radialGradient id="SVGID_1_" cx="1126.542" cy="418.5996" r="9.0969" fx="1127.6547" fy="418.4585" gradientTransform="matrix(-1.9946 0 0 1.9946 2349.1333 -425.8553)" gradientUnits="userSpaceOnUse">
	<stop  offset="0" style={{stopColor:"#FFFFFF"}} />
	<stop  offset="0.1275" style={{stopColor:"#EED8D9"}} />
	<stop  offset="0.3622" style={{stopColor:"#D39698"}} />
	<stop  offset="0.5754" style={{stopColor:"#BC6264"}} />
	<stop  offset="0.7595" style={{stopColor:"#AC3C3F"}} />
	<stop  offset="0.9071" style={{stopColor:"#A32528"}} />
	<stop  offset="1" style={{stopColor:"#9F1D20"}} />
</radialGradient>
<circle fill="url(#SVGID_1_)" cx="98.03" cy="415.923" r="18.145"/>

<circle fill="url(#SVGID_1_)" cx="192.03" cy="42.923" r="18.145"/>

<circle fill="url(#SVGID_3_)" cx="343.03" cy="720.56" r="9.073"/>

<circle fill="url(#SVGID_4_)" cx="363.03" cy="746.56" r="9.073"/>

<circle fill="url(#SVGID_5_)" cx="317.03" cy="691.56" r="9.073"/>

<circle fill="url(#SVGID_6_)" cx="293.03" cy="664.56" r="9.073"/>

<circle fill="url(#SVGID_7_)" cx="265.03" cy="636.56" r="9.073"/>

<circle fill="url(#SVGID_8_)" cx="240.03" cy="610.56" r="9.073"/>

<circle fill="url(#SVGID_9_)" cx="216.03" cy="582.56" r="9.073"/>

<circle fill="url(#SVGID_10_)" cx="195.03" cy="553.56" r="9.073"/>

<circle fill="url(#SVGID_11_)" cx="174.03" cy="527.56" r="9.073"/>

<circle fill="url(#SVGID_12_)" cx="156.03" cy="499.56" r="9.073"/>

<circle fill="url(#SVGID_13_)" cx="135.03" cy="471.56" r="9.073"/>

<circle fill="url(#SVGID_14_)" cx="61.03" cy="355.56" r="9.073"/>

<circle fill="url(#SVGID_15_)" cx="40.03" cy="321.56" r="9.073"/>

<circle fill="url(#SVGID_16_)" cx="26.03" cy="287.56" r="9.073"/>

<circle fill="url(#SVGID_17_)" cx="22.03" cy="250.56" r="9.073"/>

<circle fill="url(#SVGID_18_)" cx="21.03" cy="213.56" r="9.073"/>

<circle fill="url(#SVGID_19_)" cx="24.03" cy="173.56" r="9.073"/>

<circle fill="url(#SVGID_20_)" cx="35.03" cy="136.56" r="9.073"/>

<circle fill="url(#SVGID_21_)" cx="58.03" cy="100.56" r="9.073"/>

<circle fill="url(#SVGID_22_)" cx="87.03" cy="67.56" r="9.073"/>

<circle fill="url(#SVGID_23_)" cx="128.03" cy="42.56" r="9.073"/>

<circle fill="url(#SVGID_24_)" cx="253.03" cy="58.56" r="9.073"/>

<circle fill="url(#SVGID_25_)" cx="287.03" cy="87.56" r="9.073"/>

<circle fill="url(#SVGID_26_)" cx="318.03" cy="118.56" r="9.073"/>

<circle fill="url(#SVGID_27_)" cx="348.03" cy="152.56" r="9.073"/>

<circle fill="url(#SVGID_28_)" cx="382.03" cy="182.56" r="9.073"/>

<path fill="none" stroke="#754C29" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" d="M421.113,764.461
	c29.456-42.936,72.589-86.668,113.131-127.627c39.166-39.568,72.262-84.478,104.257-129.891
	c39.993-56.765,79.311-114.555,113.429-175.08c32.824-58.23,36.568-159.909-1.89-221.377c-48.854-78.086-137.229-93.749-209-46.854
	C498.298,91.56,429.957,190.487,400.957,190.487"/>

<circle fill="url(#SVGID_1_)" cx="701.113" cy="415.923" r="18.145"/>

<circle fill="url(#SVGID_1_)" cx="607.113" cy="42.923" r="18.145"/>

<circle fill="url(#SVGID_1_)" cx="456.113" cy="720.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="434.113" cy="745.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="481.113" cy="689.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="505.113" cy="663.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="534.113" cy="636.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="559.113" cy="610.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="583.113" cy="582.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="604.113" cy="553.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="625.113" cy="527.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="643.113" cy="499.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="664.113" cy="471.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="738.113" cy="355.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="759.113" cy="321.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="773.113" cy="287.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="777.113" cy="250.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="778.113" cy="213.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="775.113" cy="173.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="764.113" cy="136.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="741.113" cy="100.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="712.113" cy="67.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="671.113" cy="42.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="546.113" cy="58.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="512.113" cy="87.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="481.113" cy="118.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="451.113" cy="152.56" r="9.073"/>

<circle fill="url(#SVGID_1_)" cx="417.113" cy="182.56" r="9.073"/>
<g>
	<g>
		<path fill="#414042" d="M402.667,812.437c0-2.577-2.09-4.668-4.667-4.668c-2.578,0-4.668,2.091-4.668,4.668
			c0,2.578,2.09,4.667,4.668,4.667C400.577,817.104,402.667,815.015,402.667,812.437z M401.346,812.437
			c0,1.845-1.501,3.345-3.346,3.345s-3.347-1.5-3.347-3.345c0-1.846,1.502-3.346,3.347-3.346S401.346,810.591,401.346,812.437z"/>
		<rect x="396" y="804.18" fill="#414042" width="4" height="5"/>
	</g>
	<g>
		<path fill="#414042" d="M417.969,762.073c-1.822,1.822-1.822,4.778,0,6.601c1.822,1.823,4.778,1.822,6.601,0
			c1.823-1.823,1.823-4.778,0-6.601C422.747,760.25,419.792,760.25,417.969,762.073z M418.903,763.006
			c1.305-1.304,3.427-1.303,4.73,0.001c1.305,1.305,1.307,3.427,0.002,4.731c-1.306,1.306-3.428,1.305-4.732,0
			S417.599,764.312,418.903,763.006z"/>
		
			<rect x="415.198" y="766.944" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 168.123 1608.5265)" fill="#414042" width="4" height="4.999"/>
	</g>
	<g>
		<path fill="#414042" d="M371.384,762.073c-1.823,1.822-1.823,4.777,0,6.601c1.822,1.822,4.778,1.823,6.601,0
			c1.822-1.822,1.822-4.778,0-6.601C376.161,760.25,373.206,760.25,371.384,762.073z M377.05,767.739
			c-1.305,1.305-3.427,1.306-4.732,0c-1.305-1.305-1.303-3.427,0.002-4.731c1.304-1.304,3.426-1.305,4.73-0.001
			C378.354,764.312,378.354,766.434,377.05,767.739z"/>
		
			<rect x="376.755" y="766.944" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -433.1442 493.185)" fill="#414042" width="4" height="4.999"/>
	</g>
	<path fill="#414042" d="M398,1023.806c-2.578,0-4.668,2.089-4.668,4.668c0,2.576,2.09,4.667,4.668,4.667
		c2.577,0,4.667-2.091,4.667-4.667C402.667,1025.895,400.577,1023.806,398,1023.806z M398,1031.819c-1.845,0-3.347-1.5-3.347-3.345
		c0-1.846,1.502-3.346,3.347-3.346s3.346,1.5,3.346,3.346C401.346,1030.319,399.845,1031.819,398,1031.819z"/>
	<polygon fill="#808285" stroke="#414042" strokeMiterlimit="10" points="434.5,1070.68 405.5,1070.68 405.5,1036.68 
		389.5,1036.68 389.5,1070.68 360.5,1070.68 360.5,1087.68 389.5,1087.68 389.5,1170.68 405.5,1170.68 405.5,1087.68 434.5,1087.68 
			"/>
	
		<line fill="none" stroke="#754C29" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" x1="398" y1="1027.18" x2="398" y2="813.18"/>
	
	<circle fill="url(#SVGID_1_)" cx="398.244" cy="990.923" r="18.145"/>	
		
	<circle fill="url(#SVGID_1_)" cx="398.244" cy="845.761" r="18.145"/>	
		
	<circle fill="url(#SVGID_1_)" cx="398.244" cy="945.56" r="9.073"/>
			
	<circle fill="url(#SVGID_1_)" cx="398.244" cy="918.342" r="9.073"/>
			
	<circle fill="url(#SVGID_1_)" cx="398.244" cy="891.125" r="9.073"/>

	<rect x="396" y="1032.18" fill="#414042" width="4" height="4"/>
	<circle fill="#939598" stroke="#6D6E71" strokeMiterlimit="10" cx="398" cy="783.765" r="22.682"/>
</g>
</svg>

 )
}
*/
export default Rosary;